body {
  margin: 0;
  font-family: sans-serif;
  background: #1a1a1a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  margin: 3rem auto 0;
  width: 24rem;
}
form div {
  width: inherit;
  margin-bottom: 1rem;
}
form label {
  display: block;
  color: #fff;
}
form input, form select, form textarea {
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: inherit;
  height: 2rem;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
  background: #0971f1;
  cursor: pointer;
}
form textarea {
  height: 5rem;
}
